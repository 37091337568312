import React from 'react';
import Robotics1 from '../images/Robotics1.jpg';
import AnimatedPage from '../animations/transitions';
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



export default function CsProjects() {
    const pictureStyle = {
        display: 'block',
        'margin-left': 'auto',
        'margin-right': 'auto',
        marginTop: '50px',
        height: (1750 / 3264) * 2448,
        width: '40%',
        border: '25px solid #e4ddca',
        borderImage: 'linear-gradient(to bottom, #e4ddca, transparent) 1 49%'
    }

    const textStyle =  {
        color: 'white',
        'text-align': 'center'
    }

    const navItemStyle = {
        color: 'white', backgroundColor: '#15639A', borderColor: 'green',
        padding: '5px', 
        fontSize: '16px',
      };

    const accordionStyle = {
        backgroundColor: 'black',
        color: 'white',
        border: '1px solid #e4ddcb',
    };

    const codeString = `/**
    Maksym's Drink Dispencer Machine: Rizz Spencer
 
    Copyright (C) 2024
    I have no idea how licenses work. 
 */
 
 #include <LiquidCrystal.h>
 #include <Keypad.h>
 
 /* Handles the pump setup */
 #define PUMP_1 8
 #define PUMP_2 9
 #define PUMP_3 10
 #define PUMP_4 11
 #define PUMP_5 12
 #define PUMP_6 13
 const int pumpPins[] = {PUMP_1, PUMP_2, PUMP_3, PUMP_4, PUMP_5, PUMP_6};
 
 /* Handles the Display setup */
 LiquidCrystal lcd(A5, A4, A3, A2, A1, A0);
 
 /* Handles the Keypad setup */
 const byte KEYPAD_ROWS = 4;
 const byte KEYPAD_COLS = 4;
 byte rowPins[KEYPAD_ROWS] = {7, 6, 5, 4};
 byte colPins[KEYPAD_COLS] = {3, 2, 1, 0};
 char keys[KEYPAD_ROWS][KEYPAD_COLS] = {
   {'1', '2', '3', 'A'},
   {'4', '5', '6', 'B'},
   {'7', '8', '9', 'C'},
   {'*', '0', '#', 'D'}
 };
 Keypad keypad = Keypad(makeKeymap(keys), rowPins, colPins, KEYPAD_ROWS, KEYPAD_COLS);
 
 void setup() {
   lcd.begin(16, 2);
   lcd.print("The Rizz-Spencer");
 
   for (int i = 0; i < 6; i++) {
     pinMode(pumpPins[i], OUTPUT);
   }
 }
 
 void activatePump(int pumpNumber) {
   for (int i = 0; i < 6; i++) {
     digitalWrite(pumpPins[i], LOW);
   }
 
   if (pumpNumber >= 0 && pumpNumber < 6) {
     digitalWrite(pumpPins[pumpNumber] - 1, HIGH);
   }
   lcd.print("Making Drink " + String(pumpNumber));
   delay(1000); // Change this if you want Spencer to Dispence for more then 1 second.
 }
 
 void loop() {
   lcd.setCursor(0, 2);
   char customKey = keypad.getKey();
 
   if (customKey){ 
     activatePump((int(customKey) - 48)); // Don't forget to shift by 48 to get integers that aren't gibberish!
   } else {
     for (int i = 0; i < 6; i++) {
       digitalWrite(pumpPins[i], LOW);
     }
     lcd.print("Making Nothing   ");
   }
 }`; // TODO Put this into another file, and import it so 

// Above is CSS code

    return (
        <AnimatedPage>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                <h3 style={{ color: 'white', flex: '0 0 45%', marginLeft: '30px' }}>
                This is the main project that I have been working on as of late. It functions like a keurig machine,
                 only that it has tubes sticking out of it that you can put into a bottle of coca-cola/pepsi/etc and it can make a drink for you. 
                In the images below, you can see the current state of it. I will wire the thing up soon, and upload pictures of it. 

                <Accordion style={accordionStyle}>
                    <AccordionSummary 
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Typography>Click me to see the code for the robot.</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <pre style={{ whiteSpace: 'pre-wrap', color: 'white' }}>
                            <code>{codeString}</code>
                        </pre>
                    </AccordionDetails>
                </Accordion>


                <hr></hr>
                Im also working on a video game adaptation of blood on the clocktower. Currently, the code just sets up a server that other 
                computers on the local network can connect to, plus some incomplete game logic. The current state of it is on &nbsp;
                <a href="https://github.com/Maksym-W/bloodOnTheClocktower" target =" blank" style={{color: 'cyan', 'text-align': 'center' }}>
                my Github. <br></br>
                </a>
                </h3>
                
                {/* <a href="#flip" target="_blank" rel="noopener noreferrer" >
                <Button variant="contained" style={navItemStyle}>Flip</Button>
                </a> */}

                <img src={Robotics1} style={{ flex: '0 0 30%', marginLeft: '60px', ...pictureStyle }}/>
            </div>
        </AnimatedPage>
    )
} 